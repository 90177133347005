

import axios from "axios";
import Formatter from '../../../components/libraries/Formatter';
import { formatMoney, formatNumber } from "../../../helpers/utils";

const formatter = new Formatter();


/**
 * Widget Component
 */
export default {
  data() {
    return {
      dateConvert: [],
      stat: {
        date_from: "-",
        date_to: "-",
        income: { period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        income_partner: { show: 0, period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        income_my: { show: 0, period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        new_users: { percent_diff: { value: '-', direction: 'equally' }, period: "-", prev_period: "-" },
        orders: {
          all: '-', prev_all: '-', all_percent_diff: { value: '-', direction: 'equally' }, gray: '-', prev_gray: '-', color: '-', prev_color: '-', color_percent_diff: { value: '-', direction: 'equally' },
          avg_check: '-', gray_percent_diff: { value: '-', direction: 'equally' }, percent_diff_check: { value: '-', direction: 'equally' },

        },
        prev_date_from: "-",
        prev_date_to: "-"
      }
    };
  },
  props: {
    datePeriod: Array
  },
  watch: {
    datePeriod: function (val) {
      let result = [];
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function (val) {
      this.getStat(val)
    }
  },
  computed: {
    colWidth(){
      let blockCnt = 1; // Блок общим доходом за период показываем всегда

      if(+this.stat.new_users.show === 1){
        blockCnt++;
      }
      if(+this.stat.income_partner.show === 1){
        blockCnt++;
      }
      if(+this.stat.income_my.show === 1){
        blockCnt++;
      }
      return 'col-lg-' + (12 / blockCnt);
    }
  },
  methods: {
    formatNumber,
    formatMoney,
    getStat(dateArr) {
      let formData = new FormData();
      formData.append("date_from", dateArr[0]);
      formData.append("date_to", dateArr[1]);
      axios
        .post(`/v1/dashboard/summury`, formData)
        .then(resp => {
          this.stat = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
  },
  created() {
    if (this.datePeriod.length) {
      let result = [];
      result[0] = formatter.jsObjectToPhp(this.datePeriod[0]);
      result[1] = formatter.jsObjectToPhp(this.datePeriod[1]);
      this.dateConvert = result;
    }
  }
};
