//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "SortBackForth",
  props: {
    sortFields: Array, // [ {code, name, direction, active} ]
  },
  watch: {
    sortFields: function(){
      this.emitSort();
    }
  },
  computed: {
    
  },
  methods: {
    selectSort(code){
      for(let key in this.sortFields){
        if(this.sortFields[key].code === code){
          if(this.sortFields[key].active){
            if(this.sortFields[key].direction == 'ASC'){
              this.$set(this.sortFields[key], 'direction', 'DESC');
            }else if(this.sortFields[key].direction == 'DESC'){
              this.$set(this.sortFields[key], 'direction', 'ASC');
            }
          }else{
            for(let keyT in this.sortFields){
              this.$set(this.sortFields[keyT], 'active', false);
            }
            this.$set(this.sortFields[key], 'active', true);
            this.$set(this.sortFields[key], 'direction', 'ASC');
          }
        }
      }
      this.emitSort();
    },
    emitSort(){
      for(let key in this.sortFields){
        if(this.sortFields[key].active){
          this.$emit('selectSort', {code: this.sortFields[key].code, direction: this.sortFields[key].direction});
        }
      }
    }
  },
  mounted(){
    this.emitSort();
  }
};
