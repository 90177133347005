
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import Stat from "./widget";
//import RevenueAnalytics from "./revenue";
//import SalesAnalytics from "./sales-analytics";
//import EarningReport from "./earning";
//import Sources from "./sources";
//import RecentActivity from "./recent-activity";
//import RevenueLocation from "./revenue-location";
//import Chat from "./chat";
//import Transaction from './transaction';
import Summury from './summury.vue';
import DatePicker from "vue2-datepicker";
import AnalyticByObjects from './analyticByObjects.vue';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Stat,
    //RevenueAnalytics,
    //SalesAnalytics,
    //EarningReport,
    //Sources,
    //RecentActivity,
    //RevenueLocation,
    //Chat,
    //Transaction,
    Summury,
    DatePicker,
    AnalyticByObjects
  },
  data() {
    return {
      title: this.$t('menuitems.dashboard.text'),
      daterange: [],
      //defaultPeriod: [],
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },
    };
  },
  watch: {

  },
  computed: {
    
  },
  created() {
    let nowDate = new Date();
    let currentDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
    let firstDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1, 0, 0, 0, 0);
    this.$set(this.daterange, 0, firstDay);
    this.$set(this.daterange, 1, currentDay);
  }
};
