
import axios from "axios";
import SortBackForth from '../../../components/SortBackForth.vue';
import Formatter from '../../../components/libraries/Formatter';
import { mapState } from "vuex";
import Popup from '../../../components/Popup.vue';
import { formatMoney, formatNumber } from "../../../helpers/utils";

const formatter = new Formatter();
/**
 * Widget Component
 */
export default {
  components: { SortBackForth, Popup },
  data() {
    return {
      sortFields: [
        {
          code: 'profit',
          name: this.$t('views.analyticByObject.sortFields.profit'),
          direction: '',
          active: false,
        },
        {
          code: 'profit_partner',
          name: this.$t('views.analyticByObject.sortFields.profitPartner'),
          direction: '',
          active: false,
        },
        {
          code: 'avg_check',
          name: this.$t('popularWords.avgCheck'),
          direction: '',
          active: false,
        },
        {
          code: 'order_count',
          name: this.$t('views.analyticByObject.sortFields.orderCount'),
          direction: 'DESC',
          active: true,
        },
        {
          code: 'page_count',
          name: this.$t('views.analyticByObject.sortFields.pageCount'),
          direction: '',
          active: false,
        },
        {
          code: 'remaining_toner',
          name: this.$t('views.analyticByObject.sortFields.remainingToner'),
          direction: '',
          active: false,
        },
      ],
      onlyErrors: false,
      dateConvert: [],
      sortParams: {},
      stat: [],
      searchString: '',
      operationStatuses: {
        'Ready': this.$t('views.analyticByObject.state.ready'),
        'Sleep': this.$t('views.analyticByObject.state.sleep'),
        'LowPower': this.$t('views.analyticByObject.state.lowPower'),
        'NeedsAttention': this.$t('views.analyticByObject.state.needsAttention'),
      },
      trayStatuses: {
        'READY': this.$t('views.analyticByObject.state.ready'),
        'REMOVE': this.$t('views.analyticByObject.state.remove'),
        'EMPTY': this.$t('views.analyticByObject.state.empty'),
      },
      downloadBlock: false,
      excelPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      excelLink: null,
      excelLoading: false,
      forceUpdateDayInfoFix: false // это костыльная переменная чтоб форсированно триггерить обновление таблицы с инфой по заказам за день
    };
  },
  props: {
    datePeriod: Array
  },
  watch: {
    datePeriod: function (val) {
      let result = [];
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function (val) {
      if(Object.keys(this.sortParams).length > 0 && !this.downloadBlock){
        this.getStat(val);
      }
    },
    sortParams: function () {
      if(this.dateConvert.length > 0 && !this.downloadBlock){
        this.getStat(this.dateConvert);
      }
    }
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedStat(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.stat){
        // Показать только с ошибками
        if(this.onlyErrors){
          if(this.stat[key].state.state == 'offline'){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.status == 'Sleep' ||
            this.stat[key].state.status == 'LowPower' ||
            this.stat[key].state.status == 'NeedsAttention'
          ){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.detail.tray.first_tray == 'REMOVE' ||
            this.stat[key].state.detail.tray.first_tray == 'EMPTY' ||
            this.stat[key].state.detail.tray.second_tray == 'EMPTY' ||
            this.stat[key].state.detail.tray.second_tray == 'EMPTY'
          ){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.detail.toner.cyan_percent < 5 ||
            this.stat[key].state.detail.toner.magenta_percent < 5 ||
            this.stat[key].state.detail.toner.yellow_percent < 5 ||
            this.stat[key].state.detail.toner.black_percent < 5
          ){
            result[result.length] = this.stat[key];
          }else if(this.stat[key].state && this.stat[key].state.detail && this.stat[key].state.detail.fault.length){
            result[result.length] = this.stat[key];
          }
          continue;
        }

        if(searchStr == ''){
          result[result.length] = this.stat[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.stat[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.stat[key];
          }
          if(!isSearched){
            let ip = ''+this.stat[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched){
            let code = ''+this.stat[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched){
            let address = this.stat[key].printerLocation.address;
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched && this.stat[key].printerFranchiseRelation){
            let legalMain = ''+this.stat[key].printerFranchiseRelation.franchRel.legalMain.legal_person_name +'';
            if(regexp.test(legalMain)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched && this.stat[key].printerFranchiseRelation){
            let legalSlave = ''+this.stat[key].printerFranchiseRelation.franchRel.legalSlave.legal_person_name +'';
            if(regexp.test(legalSlave)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }

          if(!isSearched){
            let name = ''+this.stat[key].name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    formatNumber,
    formatMoney,
    timeToShort(dt){
      return formatter.utcPhpDateTimeToTime(dt);
    },
    getApexOptions(dates){
      return {
          dataLabels: {
              enabled: false
          },
          stroke: {
              curve: 'smooth',
              width: 3,
          },
          colors: ['#556ee6', '#34c38f'],
          xaxis: {
              type: 'date',
              categories: dates,
          },
          grid: {
              borderColor: '#f1f1f1',
          },
          tooltip: {
              x: {
                  format: 'dd/MM/yy HH:mm'
              },
          }
      };
    },
    setSort(val) {
      this.sortParams = val;
    },
    filterStatData(data) {
      for (let key in data) {
        data[key].show_order_list = false;

        if (!data[key].orders) {
          continue;
        }

        if (Object.keys(data[key].orders).length > 0) {
          for (let keyDay in data[key].orders) {
            data[key].orders[keyDay].show_orders = false;
          }
        }
      }
      return data;
    },
    getStat(dateArr) {
      this.downloadBlock = true;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("date_from", dateArr[0]);
      formData.append("date_to", dateArr[1]);
      formData.append("sort_params", JSON.stringify(this.sortParams));
      axios
        .post(`/v1/dashboard/objects-analytic`, formData)
        .then(resp => {
          this.downloadBlock = false;
          this.stat = this.filterStatData(resp.data);
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    updatePrinterState(sn) {
      let formData = new FormData();
      formData.append("printer_sn", sn);
      axios
        .post(`/v1/dashboard/update-printer-state`, formData)
        .then(resp => {
          for (let key in this.stat) {
            if (this.stat[key].serial_number == resp.data.serial_number) {
              this.stat[key].state = resp.data.state;
            }
          }
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    downloadExcel(){
      let formData = new FormData();

      for(let key in this.computedStat){
        formData.append('printer_ids[]', this.computedStat[key].id);
      }
      formData.append("date_from", this.dateConvert[0]);
      formData.append("date_to", this.dateConvert[1]);
      this.excelLoading = true;
      axios
        .post(`/v1/dashboard/object-export-excel`, formData)
        .then(resp => {
          this.excelLink = resp.data.download_link;
          this.showExcelPopup();
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        })
        .finally(() => {
          this.excelLoading = true;
        });
    },
    showExcelPopup(){
      this.excelPopup.show = true;
    },
    closeExcelPopup(){
      this.excelPopup.show = false;
    },
    toggleShowDetailedStatistic(item) {
      if (!item.show_order_list) {
        item.show_order_list = true;

        if (!item.chart || !item.orders) {
          // Подгружаем детальную статистику по принтеру
          const formData = new FormData();
          formData.append('printer_sn', item.serial_number);
          formData.append("date_from", this.dateConvert[0]);
          formData.append("date_to", this.dateConvert[1]);
          axios.post('/v1/dashboard/object-statistic', formData)
              .then(response => {
                item.chart = response.data.chart;
                item.orders = response.data.orders;

                // FIXME: Это костыль, чтобы форсированно обновить список принтеров
                item.show_order_list = false;
                this.$nextTick(() => { item.show_order_list = true; });
              })
              .catch(err => {
                this.$store.dispatch("addNotification", {
                  text: err.response.data.message,
                  time: 6,
                  color: "danger"
                });
              });
        }
      } else {
        item.show_order_list = false;
      }
    },
    toggleShowDayStatistic(day) {
      if (!day.show_orders) {
        day.show_orders = true;
      } else {
        day.show_orders = false;
      }
      this.forceUpdateDayInfoFix = false;
      this.$nextTick(() => { this.forceUpdateDayInfoFix = true; });
    }
  },
  created() {
    if (this.datePeriod.length) {
      let result = [];
      result[0] = formatter.jsObjectToPhp(this.datePeriod[0]);
      result[1] = formatter.jsObjectToPhp(this.datePeriod[1]);
      this.dateConvert = result;
    }
  }
};
